import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input } from "reactstrap";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { setModalAdd, setShowNotification } from "../../../store/reducers/widgets/WidgetsSlice";
import AxiosClient from "../../../api/AxiosClient";
import DataTable from "react-data-table-component";

interface ModalAddPositionProps {
    updateData: any;
    onSuccess: () => void;
}

const ModalAddPosition: React.FC<ModalAddPositionProps> = ({ updateData, onSuccess }) => {
    const dispatch = useAppDispatch();
    const { modalAdd } = useAppSelector(state => state.WidgetReducer);

    const [name, setName] = useState('');
    const [positions, setPositions] = useState<any[]>([]);
    const [selectedPositionIds, setSelectedPositionIds] = useState<number[]>([]);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        AxiosClient.post('/positions')
            .then(r => {
                setPositions(r.data);
            })
            .catch(error => {
                console.log('Ошибка загрузки старых должностей:', error);
            });
    }, []);

    useEffect(() => {
        if (modalAdd) {
            setName('');
            setSelectedPositionIds([]);
            setSearchQuery('');

            if (updateData) {
                setName(updateData.name);
                setSelectedPositionIds(updateData.positions.map((pos: any) => pos.id));
            }
        }
    }, [modalAdd, updateData]);

    const toggle = () => {
        dispatch(setModalAdd(false));
    };

    const handleSubmit = () => {
        const data = {
            name,
            position_ids: selectedPositionIds,
        };

        const request = updateData
            ? AxiosClient.post(`/new-position/update/${updateData.id}`, data)
            : AxiosClient.post('/new-position/add', data);

        request
            .then(() => {
                dispatch(setShowNotification({
                    type: "success",
                    message: updateData ? "Должность обновлена" : "Должность добавлена",
                    show: true
                }));
                onSuccess();
                toggle();
            })
            .catch(error => {
                console.log(error);
                dispatch(setShowNotification({
                    type: "error",
                    message: updateData ? "Ошибка при обновлении должности" : "Ошибка при добавлении должности",
                    show: true
                }));
            });
    };

    const filteredPositions = positions.filter(position =>
        position.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const columns = [
        {
            name: <span className="font-weight-bold">Название</span>,
            selector: (row: any) => row.name,
            sortable: true,
            wrap: true,
        },
        {
            name: <span className="font-weight-bold">Выбрать</span>,
            cell: (row: any) => (
                <input
                    type="checkbox"
                    checked={selectedPositionIds.includes(row.id)}
                    onChange={() => {
                        setSelectedPositionIds(prev =>
                            prev.includes(row.id)
                                ? prev.filter(id => id !== row.id)
                                : [...prev, row.id]
                        );
                    }}
                />
            ),
            width: '100px',
        },
    ];

    return (
        <Modal isOpen={modalAdd} toggle={toggle} size="lg">
            <ModalHeader toggle={toggle} className="p-3 bg-soft-primary">
                {updateData ? 'Редактировать должность' : 'Добавить новую должность'}
            </ModalHeader>
            <ModalBody>
                <Form>
                    <FormGroup>
                        <Label for="name">Название новой должности</Label>
                        <Input
                            type="text"
                            name="name"
                            id="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Введите название"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>Привязать старые должности</Label>
                        <div className="mb-3">
                            <Input
                                type="text"
                                placeholder="Поиск по названию"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        </div>
                        <DataTable
                            columns={columns}
                            data={filteredPositions}
                            noDataComponent="Нет данных"
                            pagination
                            highlightOnHover
                            customStyles={{
                                rows: {
                                    highlightOnHoverStyle: {
                                        backgroundColor: "#ffebb5"
                                    },
                                },
                            }}
                        />
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={handleSubmit}>
                    {updateData ? 'Сохранить' : 'Добавить'}
                </Button>{' '}
            </ModalFooter>
        </Modal>
    );
};

export default ModalAddPosition;