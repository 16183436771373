import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardFooter, Col, Row } from "reactstrap";
import AxiosClient from "../../../api/AxiosClient";
import DataTable from "react-data-table-component";
import { setModalAdd, setShowNotification } from "../../../store/reducers/widgets/WidgetsSlice";
import { useAppDispatch } from "../../../hooks/redux";
import ModalAddPosition from "./ModalAddPosition";
import NotificationShow from "../../widgets/NotificationShow";

const ListPosition = () => {
    const dispatch = useAppDispatch();

    const [search, setSearch] = useState('');
    const [dataFiltering, setDataFiltering] = useState<any[]>([]);
    const [newPositions, setNewPositions] = useState<any[]>([]);
    const [updateData, setUpdateData] = useState<any>(null);

    useEffect(() => {
        fetchNewPositions();
    }, []);

    const fetchNewPositions = () => {
        AxiosClient.post('/new-positions')
            .then(r => {
                setDataFiltering(r.data);
                setNewPositions(r.data);
            })
            .catch(error => {
                console.log('Ошибка загрузки новых должностей:', error);
            });
    };

    useEffect(() => {
        setDataFiltering(filtering());
    }, [search, newPositions]);

    const filtering = () => {
        let data = newPositions;

        if (search) {
            data = data.filter((e: any) => e.name.toLowerCase().includes(search.toLowerCase()));
        }

        return data;
    };

    const removeNewPosition = (id: any) => {
        AxiosClient.post(`/new-position/archive/${id}`)
            .then(() => {
                fetchNewPositions();
                dispatch(setShowNotification({ type: "success", message: "Готово, новая должность в архиве", show: true }));
            })
            .catch((error) => {
                console.log(error);
                dispatch(setShowNotification({ type: "error", message: "Ошибка, не удалось добавить в архив", show: true }));
            });
    };

    const columns: any = [
        {
            id: "name",
            name: <span className='font-weight-bold accessibility_size_table'>Наименование</span>,
            selector: (row: any) => row.name,
            cell: (row: any) => <span className='accessibility_size_table'>{row.name}</span>,
            wrap: true,
            sortable: true,
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'>Организация</span>,
            selector: (row: any) => row.organization?.name || 'Не указано',
            wrap: true,
            sortable: true,
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'></span>,
            selector: (row: any) => (
                <i
                    className="bx bx-pencil fs-24 cursor-pointer me-3"
                    onClick={() => {
                        setUpdateData(row);
                        dispatch(setModalAdd(true));
                    }}
                />
            ),
            width: '60px'
        },
        {
            name: <span className='font-weight-bold accessibility_size_table'></span>,
            selector: (row: any) => (
                <i
                    className="bx bx-archive-in fs-24 cursor-pointer"
                    onClick={() => removeNewPosition(row.id)}
                />
            ),
            width: '60px'
        }
    ];

    return (
        <>
            <Row>
                <Col xxl={3} xl={3} lg={6}>
                    <label className="w-100">Наименование:
                        <input
                            type="text"
                            className='form-control'
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </label>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className="mt-2">
                        <CardBody>
                            <DataTable
                                columns={columns}
                                data={dataFiltering}
                                noDataComponent={'Нет данных'}
                                pagination
                                paginationRowsPerPageOptions={[5, 10, 20, 30]}
                                paginationComponentOptions={{
                                    rowsPerPageText: 'Видно новых должностей:',
                                    rangeSeparatorText: 'из'
                                }}
                                highlightOnHover
                                customStyles={{
                                    rows: {
                                        highlightOnHoverStyle: {
                                            backgroundColor: "#ffebb5"
                                        },
                                    },
                                    expanderRow: {
                                        style: {
                                            backgroundColor: "#ffebb5",
                                            paddingTop: '5px',
                                            paddingBottom: '5px'
                                        },
                                    },
                                }}
                                onRowClicked={(row) => {
                                    if (window.innerWidth <= 768) {
                                        setUpdateData(row);
                                        dispatch(setModalAdd(true));
                                    }
                                }}
                                onRowDoubleClicked={(row) => {
                                    if (window.innerWidth > 768) {
                                        setUpdateData(row);
                                        dispatch(setModalAdd(true));
                                    }
                                }}
                            />
                        </CardBody>
                        <CardFooter>
                            <button
                                className="btn btn-primary"
                                onClick={() => {
                                    setUpdateData(null);
                                    dispatch(setModalAdd(true));
                                }}
                            >
                                Добавить
                            </button>
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
            <ModalAddPosition
                updateData={updateData}
                onSuccess={fetchNewPositions}
            />
            <NotificationShow />
        </>
    );
};

export default ListPosition;